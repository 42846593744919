import * as React from "react";
import { useContext } from "react";

import { getNextPayoutDate } from "@simplyk/common";

import { OrganizationCountry, PayoutInterval, StripeAccountObject, StripeCapabilityStatus } from "../gql/gql-types";
import { useGetStripeCustomAccountQuery } from "../gql/queries/generated/authQuery";
import { GetIssuingCardQuery, useGetIssuingCardQuery } from "../gql/queries/generated/issuingQuery";
import { useGetStripeAccountBalanceQuery } from "../gql/queries/generated/organizationQuery";

import { useCurrentUserContext } from "./CurrentUserContext";

interface StripeAccountContextProps {
  stripeAccountHasRequirements: boolean;
  transferIsActive: boolean;
  transferIsPending: boolean;
  getStripeCustomAccountLoading: boolean;
  requirementDelay?: string;
  stripeCustomBalanceLoading: boolean;
  hasStripeCustomFlowStarted: boolean;
  isStripeCustomAccountActive: boolean;
  isStripeCustomBankConnected?: boolean;
  refetchOrganization: () => void;
  pendingCustomBalance: number;
  availableCustomBalance: number;
  stripeCustomBalance: number;
  refetchStripeAccount: () => void;
  isBankRequirementDue?: boolean;
  futureRequirements: string[];
  stripeCustomAccount?: StripeAccountObject | null;
  hasAtLeastOnePayout?: boolean | null;
  hasOnlyOnePayout?: boolean | null;
  stripeCustomAccountId?: string | null;
  issuingBalance: number;
  issuingPendingBalance: number;
  nextPayoutDate?: Date;
  refetchBalance: () => void;
  stripeIssuingCard: NonNullable<GetIssuingCardQuery["getIssuingCard"]["object"]>["issuingCard"];
  refetchIssuingCard: () => void;
  isStripeIssuingCapabilityActive: boolean;
  requirementsCurrentlyDue?: string[];
  isStripePendingVerification: boolean;
  financialAccount: { hasFinancialAccount: boolean; isLoading: boolean };
}

const StripeAccountContext = React.createContext<StripeAccountContextProps>({
  stripeAccountHasRequirements: true,
  transferIsActive: true,
  transferIsPending: false,
  getStripeCustomAccountLoading: true,
  requirementDelay: "",
  stripeCustomBalanceLoading: true,
  hasStripeCustomFlowStarted: false,
  isStripeCustomAccountActive: false,
  refetchOrganization: () => {},
  pendingCustomBalance: 0,
  availableCustomBalance: 0,
  stripeCustomBalance: 0,
  refetchStripeAccount: () => {},
  isStripeCustomBankConnected: true,
  isBankRequirementDue: false,
  futureRequirements: [],
  stripeCustomAccount: null,
  hasAtLeastOnePayout: null,
  hasOnlyOnePayout: null,
  stripeCustomAccountId: null,
  issuingBalance: 0,
  issuingPendingBalance: 0,
  nextPayoutDate: undefined,
  refetchBalance: () => {},
  stripeIssuingCard: null,
  refetchIssuingCard: () => {},
  isStripeIssuingCapabilityActive: false,
  isStripePendingVerification: false,
  financialAccount: { hasFinancialAccount: false, isLoading: true },
});

export const useStripeAccountContext = (): StripeAccountContextProps => {
  return useContext(StripeAccountContext);
};

export const StripeAccountProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const {
    isStripeCustomAccountActive,
    hasStripeCustomFlowStarted,
    refetchOrganization,
    currentUser,
    isOrganization,
    organization,
  } = useCurrentUserContext();
  const skip = !currentUser || !isOrganization;
  const {
    data: getStripeCustomAccount,
    loading: getStripeCustomAccountLoading,
    refetch: refetchStripeAccount,
  } = useGetStripeCustomAccountQuery({ skip, fetchPolicy: "network-only" });
  const {
    data: getStripeCustomBalance,
    loading: stripeCustomBalanceLoading,
    refetch: refetchBalance,
  } = useGetStripeAccountBalanceQuery({
    skip,
  });

  const { data: getIssuingCard, refetch: refetchIssuingCard } = useGetIssuingCardQuery({
    skip: skip || !organization?.featureIssuingEnabled,
  });
  const stripeIssuingCard = getIssuingCard?.getIssuingCard.object?.issuingCard;

  const issuingBalance = getStripeCustomBalance?.getStripeAccountBalance?.object?.issuing?.available?.[0].amount || 0;
  const issuingPendingBalance =
    getStripeCustomBalance?.getStripeAccountBalance?.object?.issuing?.pending?.[0].amount || 0;

  const pendingCustomBalance = getStripeCustomBalance?.getStripeAccountBalance?.object?.pending?.[0].amount || 0;
  const availableCustomBalance = getStripeCustomBalance?.getStripeAccountBalance?.object?.available?.[0].amount || 0;
  const stripeCustomBalance = pendingCustomBalance + availableCustomBalance;

  // TODO on filter out le future requirement "external_account": to see with product a better handling
  const futureRequirements =
    getStripeCustomAccount?.getStripeCustomAccount?.object?.future_requirements?.currently_due?.filter(
      (requirement) => requirement !== "external_account"
    ) || [];

  const requirementsCurrentlyDue =
    getStripeCustomAccount?.getStripeCustomAccount?.object?.requirements?.currently_due?.filter(
      (requirement) => requirement !== "external_account"
    ) || [];

  const stripeAccountHasRequirements = Boolean(requirementsCurrentlyDue.length > 0 || futureRequirements?.length > 0);

  const isBankRequirementDue =
    getStripeCustomAccount?.getStripeCustomAccount?.object?.requirements?.currently_due?.includes("external_account");
  const requirementsDeadline = getStripeCustomAccount?.getStripeCustomAccount?.object?.requirements?.current_deadline;
  const requirementDelay = requirementsDeadline
    ? Math.ceil((requirementsDeadline - Date.now()) / (1000 * 60 * 60 * 24)).toString()
    : undefined;
  // Stripe pending verification only for Canadian organizations
  const isStripePendingVerification =
    Boolean(getStripeCustomAccount?.getStripeCustomAccount?.object?.requirements?.pending_verification?.length) &&
    organization?.country === OrganizationCountry.Canada;
  const transferIsActive =
    getStripeCustomAccount?.getStripeCustomAccount?.object?.capabilities.transfers === StripeCapabilityStatus.Active;
  const transferIsPending =
    getStripeCustomAccount?.getStripeCustomAccount?.object?.capabilities.transfers === StripeCapabilityStatus.Pending;
  const stripeCustomAccountId = getStripeCustomAccount?.getStripeCustomAccount?.object?.id;
  const isStripeIssuingCapabilityActive = Boolean(
    getStripeCustomAccount?.getStripeCustomAccount?.object?.capabilities.card_issuing === StripeCapabilityStatus.Active
  );

  const isStripeCustomBankConnected = getStripeCustomAccount?.getStripeCustomAccount?.object?.isBankConnected;

  const stripeCustomAccount = getStripeCustomAccount?.getStripeCustomAccount?.object as StripeAccountObject;
  const nextPayoutDate = React.useMemo(() => {
    if (organization?.featureIssuingEnabled && !organization?.featureTreasuryEnabled) {
      return getNextPayoutDate(new Date(), PayoutInterval.Weekly);
    }
    return stripeCustomAccount?.payoutInterval && getNextPayoutDate(new Date(), stripeCustomAccount?.payoutInterval);
  }, [organization?.featureIssuingEnabled, organization?.featureTreasuryEnabled, stripeCustomAccount?.payoutInterval]);

  return (
    <StripeAccountContext.Provider
      value={{
        financialAccount: {
          hasFinancialAccount: Boolean(organization?.featureTreasuryEnabled),
          isLoading: !organization,
        },
        stripeAccountHasRequirements,
        transferIsActive,
        transferIsPending,
        getStripeCustomAccountLoading,
        issuingBalance,
        issuingPendingBalance,
        requirementDelay,
        stripeCustomBalanceLoading,
        hasStripeCustomFlowStarted,
        isStripeCustomAccountActive,
        refetchOrganization,
        pendingCustomBalance,
        availableCustomBalance,
        stripeCustomBalance,
        stripeCustomAccount,
        refetchStripeAccount,
        isStripeCustomBankConnected,
        isBankRequirementDue,
        futureRequirements,
        hasAtLeastOnePayout: getStripeCustomAccount?.getStripeCustomAccount?.object?.hasAtLeastOnePayout,
        hasOnlyOnePayout: getStripeCustomAccount?.getStripeCustomAccount?.object?.hasOnlyOnePayout,
        stripeCustomAccountId,
        nextPayoutDate,
        refetchBalance,
        stripeIssuingCard,
        refetchIssuingCard,
        isStripeIssuingCapabilityActive,
        requirementsCurrentlyDue,
        isStripePendingVerification,
      }}
    >
      {children}
    </StripeAccountContext.Provider>
  );
};
